import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnSelectorComponent } from '../column-selector/column-selector.component';
import { ColumnConfig } from './column_table_model';
import { ITEMS_PER_PAGE } from 'src/app/core/config/pagination.constants';

@Component({
  selector: 'milestone-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  visibleColumns: Array<ColumnConfig> = [];
  actionColumn: ColumnConfig = { header: 'Action', field: 'action' };

  @Input() columns: Array<ColumnConfig> = [];
  @Input() data: any[] = [];
  @Input() itemsPerPage = ITEMS_PER_PAGE;
  @Input() totalItems = 0;
  @Input() dropdownFilters: Array<{
    label: string;
    options: any[];
    selected: any;
  }> = [];
  
  @Input() customComponent: TemplateRef<any> | null = null;
  @Input() poupdata: TemplateRef<any> | null = null;
  @Input() actionButtonText = 'Create';
  @Input() exportButtonText = 'Export';
  @Output() actionClick = new EventEmitter<void>();
  @Output() rowClick = new EventEmitter<void>();
  @Output() nextPage = new EventEmitter<void>();
  @Output() exportClick = new EventEmitter<void>();

  filteredData: any[] = [];
  currentPage = 1;
  filters: { [key: string]: string } = {};

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.filteredData = [...this.data];
    this.visibleColumns = [...this.columns, this.actionColumn];
  }

  ngOnChanges(): void {
    this.filteredData = [...this.data];
  }

  onGlobalSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value.toLowerCase();

    console.log('Global Search Term:', searchTerm);
    this.filteredData = this.data.filter((item) =>
      this.visibleColumns.some((col) =>
        item[col.field]?.toString().toLowerCase().includes(searchTerm)
      )
    );
  }

  openColumnSelector(): void {
    const dialogRef = this.dialog.open(ColumnSelectorComponent, {
      width: '250px',
      height: '400px',
      data: {
        columns: this.columns,
        selectedColumns: this.visibleColumns,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.visibleColumns = [...result];
        if (!this.visibleColumns.includes(this.actionColumn)) {
          this.visibleColumns.push(this.actionColumn);
        }
      }
    });
  }

  onActionClick(): void {
    this.actionClick.emit();
  }

  onExportClick(): void {
    this.exportClick.emit();
  }

  onRowClick(event: MouseEvent, rowData: any): void {
    const target = event.target as HTMLElement;

    // Check if the click is within the dropdown menu
    if (target.closest('.dropdown') || target.closest('.dropdown-menu')) {
      return; // Do nothing if the click is within the dropdown
    }

    // Otherwise, proceed with the row click logic
    console.log('Row clicked:', rowData);
    this.rowClick.emit(rowData); // Emit the row data
  }

  onEdit(rowData: any): void {
    // Handle edit logic here
    console.log('Edit clicked for row:', rowData);
  }

  onDelete(rowData: any): void {
    // Handle delete logic here
    console.log('Delete clicked for row:', rowData);
  }

  onNextItem(event: any): void {
    console.log('Next Item:', event);
    // this.currentPage = event;
    this.nextPage.emit(event);
  }
}
