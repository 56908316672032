<div>
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="text-lg fw-bold">Cities</h2>
  </div>
  <div class="mt-8">
    <milestone-table
      [columns]="columns"
      [data]="cities"
      (rowClick)="handleRowAction($event)"
      (nextPage)="onNextPage($event)"
      [totalItems]="totalElements"
      [itemsPerPage]="pageInit"
      [dropdownFilters]="dropdownFilters"
      [actionButtonText]="'Create City'"
      [exportButtonText]="'Export Cities'"
      (actionClick)="onClickCreate()"
      (exportClick)="onExportClick()"
    ></milestone-table>
  </div>

  <ng-template #customTemplate>
    <!-- Custom component content goes here -->
    <div class="custom-content-table">
      <p>Custom content here for city</p>
    </div>
  </ng-template>
</div>
