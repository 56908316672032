<!-- <canvas [id]="idCanvas"> </canvas> -->

<div class="stat">
  <canvas id="{{ idCanvas }}" class="chart-container"> </canvas>
</div>

<div class="stat-info">
  <h4>{{ title }}</h4>
  <div class="legend">
    <div class="legend-item">
      <span class="legend-color" style="background-color: #f68d2b"> </span>

      <span> Non traités: 600 (60%)</span>
    </div>

    <div class="legend-item">
      <span class="legend-color" style="background-color: #20bf55"> </span>

      <span> Traités: 400 (40%)</span>
    </div>
  </div>
</div>
