import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'src/app/shared/utils/file.service';
import { ModalFileComponent } from '../../../shared/modal-file/modal-file.component';
import { IUserVM } from '../../user/models/user-vm.model';
import { ITrackingShipmentVM } from '../models/tracking-shipment-vm.model';

@Component({
  selector: 'milestone-tracking-shipment',
  templateUrl: './tracking-shipment.component.html',
  styleUrls: ['./tracking-shipment.component.scss'],
})
export class TrackingShipmentComponent implements OnInit {
  @Input() trackingShipments: ITrackingShipmentVM[] = [];
  @Input() documents: ITrackingShipmentVM[] = [];
  @Input() notifications: any[] = [];

  constructor(private fileService: FileService,  private modalService: NgbModal){}

  ngOnInit(): void {}

  getUserName(user: IUserVM | null): string {
    return user ? user.firstname + ' ' + user.lastname : '-';
  }

  splitFileUrls(urls?: string | null): string[] {
    if (!urls) {
      return [];
    }
    return urls.split(',').map((url) => url.trim());
  }

  download(url: string): void {
    this.downloadImageByUrl(url);
  }

  open(url: string): void {
    const modalRef = this.modalService.open(ModalFileComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          windowClass: 'modal-size',
        });
    
        modalRef.componentInstance.title = this.divTitle(url);
        modalRef.componentInstance.icon = url;
  }

  /* ===============================
            private methods
  ==================================*/

  private downloadImageByUrl(urlFile: string): void {
    this.fileService.downloadFile(urlFile).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = this.getDocumentName(urlFile); // Replace with the desired filename
      link.click(); // Triggers the download
      window.URL.revokeObjectURL(url); // Clean up the URL after download
    });
  }

  private getDocumentName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  private divTitle(url:string): string{
    const nameFile = url.split('/').slice(-1)[0].replace(/\.[^/.]+$/, '');
    const title= nameFile.match(/.{1,30}/g)?.join(' ');
    return title?? '';
  }
}
