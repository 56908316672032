<div class="row">
  <div *ngFor="let operator of filteredOperators()" class="col-mile">
    <milestone-operators-card
      [operator]="operator"
      (actionTriggered)="handleOperatorAction(operator)"
      (viewMap)="handleViewMap(operator)"
    >
    </milestone-operators-card>
  </div>
</div>
