<div class="wrapper">

  <div class="location-image">
    <div class="img-container">
      <img
        class="img-profile"
        src="assets/images/png/operator_tracking.png"
        alt="Profile Image"
      />
      <div class="vehicle-badge">
        <img
          class="vehicle-icon"
          src="assets/images/svg/vehicle.svg"
          alt="Vehicle Type"
        />
      </div>
    </div>
  
  </div>

  <div class="body-container">
    <div class="profile">
      <div class="profile-section">
        <div class="profile-info">
          <div class="profile-name">
            <h3 class="name">
              {{ operator?.firstname }} {{ operator?.lastname }}
            </h3>

            <div class="rating-wallet">
              <div class="rating">
                <span class="rating-text">{{ "3.5" }}/5</span>
                <img
                  class="rating-icon"
                  src="assets/images/svg/star.svg"
                  alt="Rating Icon"
                />
              </div>
              <div class="wallet">
                <i class="amount">{{ "4 589" }} DH</i>
              </div>
            </div>
          </div>

          <p class="role">Livreur & Ramasseur</p>
        </div>
      </div>

      <!-- Rating and Wallet Section -->
    </div>
    <div class="status-card">
      <!-- Profile And Wallet -->

      <!-- Delivery & Pickup Statistics -->
      <div class="stats-container">
        <milestone-gauge-operator
          [idCanvas]="operator?.idUser?.toString() + 'L'"
          [gaugeValue]="20"
          [title]="'LIVRAISON'"
        ></milestone-gauge-operator>

        <div class="stat">
          <milestone-gauge-operator
            [idCanvas]="operator?.idUser?.toString() + 'R'"
            [gaugeValue]="70"
            [title]="'RAMASSAGE'"
          ></milestone-gauge-operator>
          <!-- 
          <h4>RAMASSAGE</h4>

          <div class="info-stats">
            <p>Non traités: {{ "600" }} (60%)</p>
            <p>Traités: {{ "400" }} (40%)</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Backlog and Call Buttons -->
  <div class="action-buttons">
    <div class="backlog">
      <img src="assets/images/svg/box.svg" alt="Backlog Icon" />
      <p>Backlog</p>
      <span>Total: {{ "784" }} Colis</span>
    </div>
    <div class="call">
      <img src="assets/images/svg/call.svg" alt="Call Icon" />
      <a href="tel:{{ operator?.mobile }}">{{ operator?.mobile }}</a>
    </div>
  </div>
</div>
