import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ElementRef,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps'; // Import GoogleMap from Angular Maps
import { Subscription } from 'rxjs';
import { WebsocketService } from '../../core/config/websocket.service';
import { AppConstant } from '../../shared/app-constant';
import { OperatorMapCardComponent } from '../../shared/operator-map-card/operator-map-card.component';
import { ShipementService } from '../shipment/services/shipement.service';
import { LocationOperator } from './model/location-operator.model';
import { IOperatorVM } from './model/operator-indicators.model';
import { ClientDataStorageService } from '../../shared/clientDataStorage/client-data-storage.service';
import { IAgencyVM } from '../agency/models/agency-vm.model';
import { IUserVM } from '../user/models/user-vm.model';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'milestone-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  // imports : [SharedModule]
})
export class LocationComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) googleMap!: GoogleMap;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;

  messageSubscription: Subscription | null = null;
  center: google.maps.LatLngLiteral = AppConstant.center;
  selectedOperator: IOperatorVM | null = null; // Track the selected operator
  zoom = AppConstant.zoom;
  operators: IOperatorVM[] = [];
  agency?: IAgencyVM;
  userConnected?: IUserVM;

  operatorPolylines: Map<string, google.maps.Polyline> = new Map();
  operatorMarkers: Map<string, google.maps.marker.AdvancedMarkerElement> =
    new Map();

  iconUrl = 'assets/images/svg/delivery.svg';

  mapOptions: google.maps.MapOptions = {
    gestureHandling: 'cooperative',
    center: this.center,

    zoom: this.zoom,
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    mapId: AppConstant.googleMapId,
  };

  searchTerm = '';
  bg = '';

  isconfirmModalOpen = false;
  isconfirmModalOpen2 = false;
  infoWindow: google.maps.InfoWindow | null = null; // Add the infoWindow here

  idAgency?: string | null;

  constructor(
    private wsService: WebsocketService,
    private viewContainerRef: ViewContainerRef,
    private shipementService: ShipementService,
    private clientStorageService: ClientDataStorageService
  ) {}

  ngOnInit(): void {
    this.getConnectedUser();
    this.getAgncy();
    // Fetch operators
    this.getOperateur();
    this.wsService.subscribeToUser(
      this.userConnected?.username ?? 'mile.stone'
    );
    this.messageSubscription = this.wsService
      .onLocation()
      .subscribe((location: LocationOperator) => {
        if (this.isconfirmModalOpen2) {
          this.handleLocationUpdate(location);
        }
      });
  }

  moveMap(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
    }
  }

  onZoom(): void {
    this.zoom = this.googleMap.getZoom() ?? this.zoom;
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  getOperateur(): void {
    this.shipementService.getOperateursWithIndicator(this.idAgency).subscribe({
      next: (response) => (this.operators = response),
      error: () => console.error('Error fetching operators'),
    });
  }

  filteredOperators(): IOperatorVM[] {
    if (!this.searchTerm) {
      return this.operators;
    }
    return this.operators.filter(
      (operator) =>
        operator.firstname
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase()) ||
        operator.lastname
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase()) ||
        operator.mobile
          .toLowerCase()
          .includes(
            this.searchTerm.startsWith('0')
              ? '00212' + this.searchTerm.substring(1)
              : this.searchTerm.toLowerCase()
          )
    );
  }

  handleClose2(): void {
    this.isconfirmModalOpen = false;
  }

  handleClose3(): void {
    this.isconfirmModalOpen2 = false;
    this.cleanUpMap(); // Nettoyer la carte (marqueurs et polylines)
  }

  handleOperatorAction(operator: IOperatorVM): void {
    this.selectedOperator = operator;
    this.isconfirmModalOpen = true;
    // Add any additional logic you want to run here
  }

  handleViewMap(operator: IOperatorVM): void {
    this.selectedOperator = operator;
    this.isconfirmModalOpen2 = true;
    setTimeout(() => {
      this.wsService.subscribeToUser(
        this.userConnected?.username ?? 'mile.stone'
      );
      this.messageSubscription = this.wsService
        .onLocation()
        .subscribe((location: LocationOperator) => {
          this.handleLocationUpdate(location);
        });
    }, 0);
  }

  cleanUpMap(): void {
    // this.operatorMarkers.forEach(marker => marker.setMap(null));
    this.operatorMarkers.clear();
    this.operatorPolylines.forEach((polyline) => polyline.setMap(null));
    this.operatorPolylines.clear();
  }

  onOperatorCardClick(operator: IOperatorVM): void {
    this.selectedOperator = operator;
    this.bg = 'grey';
    const marker = this.operatorMarkers.get(operator.idUser.toString());
    if (marker?.position) {
      let lat: number;
      let lng: number;

      // Check if position is LatLng or LatLngLiteral
      if (
        typeof marker.position.lat === 'function' &&
        typeof marker.position.lng === 'function'
      ) {
        // If it's a LatLng, call the .lat() and .lng() methods
        lat = marker.position.lat();
        lng = marker.position.lng();
      } else {
        // If it's a LatLngLiteral, access the lat and lng properties directly
        lat = (marker.position as google.maps.LatLngLiteral).lat;
        lng = (marker.position as google.maps.LatLngLiteral).lng;
      }

      // Pan the map to the marker position
      this.googleMap.panTo({ lat, lng });
      this.googleMap.zoom = 15;

      // Show the info window with the correct position
      this.showInfoWindow(operator, lat, lng);
    } else {
      console.warn('No location available for this operator');
    }
  }

  onMarkerClick(sender: string): void {
    const operator = this.operators.find(
      (op) => op.idUser.toString() === sender
    );
    if (operator) {
      this.selectedOperator = operator;

      const marker = this.operatorMarkers.get(sender);
      if (marker?.position) {
        let lat: number;
        let lng: number;

        // Check if position is LatLng or LatLngLiteral
        if (
          typeof marker.position.lat === 'function' &&
          typeof marker.position.lng === 'function'
        ) {
          // If it's a LatLng, call the .lat() and .lng() methods
          lat = marker.position.lat();
          lng = marker.position.lng();
        } else {
          // If it's a LatLngLiteral, access the lat and lng properties directly
          lat = (marker.position as google.maps.LatLngLiteral).lat;
          lng = (marker.position as google.maps.LatLngLiteral).lng;
        }

        // Pan the map to the marker position
        this.googleMap.panTo({ lat, lng });

        // Show the info window with the correct position
        this.showInfoWindow(operator, lat, lng);
      }
    } else {
      console.warn('Operator not found');
    }
  }

  /* ====================================
        private methods
  ======================================== */

  private handleLocationUpdate(operator: LocationOperator): void {
    const sender = operator.sender;
    if (sender === this.selectedOperator?.idUser.toString()) {
      const position = new google.maps.LatLng(
        +operator.latitude,
        +operator.longitude
      );

      let polyline = this.operatorPolylines.get(sender);

      if (!polyline) {
        polyline = new google.maps.Polyline({
          path: [position], // initialize with the first position
          strokeColor: this.getRandomColor(),
          strokeOpacity: 1.0,
          strokeWeight: 4,
          map: this.googleMap.googleMap!,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 2,
                fillColor: '#3737c3',
                fillOpacity: 1,
                strokeColor: '#3737c3',
              },
              offset: '0',
              repeat: '6px',
            },
          ],
        });

        this.operatorPolylines.set(sender, polyline);
      } else {
        const path = polyline.getPath();
        path.push(position);
        // this.operatorPolylines.set(sender, polyline);
      }

      let marker = this.operatorMarkers.get(sender);

      if (!marker) {
        const iconElement = document.createElement('div');
        iconElement.innerHTML = `<img src="${this.iconUrl}" style="width: 35px; height: 35px;" />`;

        marker = new google.maps.marker.AdvancedMarkerElement({
          position,
          map: this.googleMap.googleMap!,
          content: iconElement,
          title: sender,
        });

        marker.addListener('click', () => {
          this.onMarkerClick(sender);
        });

        this.operatorMarkers.set(sender, marker);
      } else {
        marker.position = position;
      }
    }
  }

  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Add a method to show the InfoWindow
  private showInfoWindow(
    operator: IOperatorVM,
    latitude: number,
    longitude: number
  ): void {
    if (this.infoWindow) {
      this.infoWindow.close(); // Close the existing info window if any
    }

    const componentRef = this.viewContainerRef.createComponent(
      OperatorMapCardComponent
    );
    componentRef.instance.operator = operator;

    const div = document.createElement('div');
    div.style.width = '220px'; // Set the width for the InfoWindow content
    div.style.minHeight = '100px'; // Set a minimum height for the card
    div.appendChild(componentRef.location.nativeElement);
    div.appendChild(componentRef.location.nativeElement);

    // Create a new InfoWindow with custom content
    this.infoWindow = new google.maps.InfoWindow({
      content: div,
      position: new google.maps.LatLng(latitude, longitude), // Position based on operator's coordinates
    });

    this.infoWindow.open(this.googleMap.googleMap); // Attach the InfoWindow to the map
  }

  private getAgncy(): void {
    this.agency = this.clientStorageService.getAgency();
    this.idAgency = this.agency?.idAgency;
  }

  private getConnectedUser(): void {
    this.userConnected = this.clientStorageService.getUser();
  }
}
