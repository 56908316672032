import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdministartionModule } from './administartion/administartion.module';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationConfigService } from './core/config/application-config.service';
import { DataConstantModule } from './data-constant/data-constant.module';
import { LoginModule } from './login/login.module';
import { EntityModule } from './modules/entity.module';
import { PopupModule } from './shared/popup/popup.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxWebstorageModule, SessionStorageService } from 'ngx-webstorage';
import { environment } from '../environments/environment'; // Access the correct environment
import { AuthGuardService } from './core/config/auth.guard.service';
import { NgbDateDayjsAdapterService } from './core/config/datepicker-adapter.service';
import { WebsocketService } from './core/config/websocket.service';
import { httpInterceptorProviders } from './core/interceptor';
import { TranslationModule } from './language/translation.module';
import { LayoutModule } from './layout/layout.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NotificationService } from './shared/notification/notification.service';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustompaginationService } from './shared/custompagination/custompagination.service';
// import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    LayoutModule,
    NgxPaginationModule,
    AppRoutingModule,
    AngularMaterialModule,
    AdministartionModule,
    DataConstantModule,
    EntityModule,
    LoginModule,
    PopupModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    // NgChartsModule,

    NgxWebstorageModule.forRoot({
      prefix: 'milestone',
      separator: '-',
      caseSensitive: true,
    }),
    TranslationModule,
    MatInputModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [
    WebsocketService,
    NotificationService,
    httpInterceptorProviders,
    AuthGuardService,
    NgbDateDayjsAdapterService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MatPaginatorIntl, useClass: CustompaginationService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    applicationConfigService: ApplicationConfigService,
    sessionStorageService: SessionStorageService,
    translateService: TranslateService
  ) {
    applicationConfigService.setEndpointPrefix(`${environment.SERVER_API_URL}`);
    const langKey = sessionStorageService.retrieve('locale') ?? 'fr';
    translateService.use(langKey);
  }
}
