import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'milestone-gauge-operator',
  templateUrl: './gauge-operator.component.html',
  styleUrls: ['./gauge-operator.component.scss'],
})
export class GaugeOperatorComponent implements AfterViewInit, OnDestroy {
  @Input() idCanvas = '';
  @Input() title = 'Gauge Title';
  @Input() gaugeValue = 90;
  // private chart: Chart | undefined;
  chart: any = [];

  doughnutChartData?: any;
  // chart: any = [];

  // Doughnut Chart Config
  doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allows custom width & height

    cutout: 80,
    plugins: {
      legend: { display: false },
    },
  };

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createGaugeChart();
    }, 700);
  }

  ngOnDestroy(): void {}

  createGaugeChart(): void {
    const ctx = document.getElementById(this.idCanvas) as HTMLCanvasElement;

    // Create the chart instance
    this.chart = new Chart(
      ctx,

      {
        type: 'doughnut',
        // height : 200,
        data: {
          labels: ['Critique', 'Avertissement', 'OK'],
          datasets: [
            {
              label: 'of Votes',
              data: [33, 33, 34],
              backgroundColor: ['#EB2D2D', '#FFA442', '#20BF55'],
              hoverBackgroundColor: ['#EB2D2D', '#FFA442', '#20BF55'],
              borderWidth: 0.5,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,

          rotation: -90,
          circumference: 180,
          cutout: '70%', // Updated from cutoutPercentage (v2) to cutout (v3+)
          plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
          },
          animation: {
            animateRotate: true,
            animateScale: true,
          },
        },

        plugins: [
          {
            afterDraw: (chart) => {
              const height = 220;
              const width = 100;
              const context = chart.ctx;
              const needleAngle = (this.gaugeValue / 100) * Math.PI;

              // **Positioning the needle pivot point**
              const centerX = 125;
              const centerY = 45;
              const needleLength = 23; // Shortened needle
              const needleColor = '#333';

              // **Save context before transformations**
              context.save();

              // **Move to pivot center and rotate**
              context.translate(centerX, centerY);
              context.rotate(-Math.PI / 2 + needleAngle);

              // **Draw the needle (flipped triangle)**
              context.beginPath();
              context.moveTo(0, -needleLength); // Tip of needle
              context.lineTo(-8, 0); // Left base
              context.lineTo(8, 0); // Right base
              context.closePath();
              context.fillStyle = needleColor;
              context.fill();

              // **Restore context after drawing needle**
              context.restore();

              // **Draw pivot circle (outer ring)**
              context.beginPath();
              context.arc(centerX, centerY, 12, 0, Math.PI * 2);
              context.fillStyle = '#555'; // Outer circle color
              context.fill();

              // **Draw pivot circle (inner ring)**
              context.beginPath();
              context.arc(centerX, centerY, 6, 0, Math.PI * 2);
              context.fillStyle = '#fff'; // Inner circle color
              context.fill();
            },
            id: this.idCanvas,
          },
        ],
      }
    );
  }
}
