export class FunctionFiltters {
  getPickupInfoNotAssigned(): string[] {
    return [
      'INFO_NEW_ORDER',
      'PICKUP_INFO_UNASSIGNED',
      'PICKUP_ISSUE_SENDER_UNAVAILABLE',
      'PICKUP_ISSUE_SENDER_UNREACHABLE',
      'PICKUP_ISSUE_CAR_ACCIDENT',
      'PICKUP_ISSUE_BROKEN_CAR',
      'PICKUP_ISSUE_WRONG_ADDRESS',
      'PICKUP_ISSUE_CANT_PARK',
      'PICKUP_ISSUE_CANT_ACCESS',
      'PICKUP_ISSUE_LOCKER_DOWN',
      'PICKUP_ISSUE_LOCKER_NOT_FOUND',
      'PICKUP_ISSUE_LOCKER_ACCOUNT_ISSUE',
    ];
  }

  getPickupInfoAssigned(): string[] {
    return ['PICKUP_INFO_ASSIGNED'];
  }

  getDeliveryInfoNotAssigned(): string[] {
    return [
      'DELIVERY_INFO_UNASSIGNED',
      'POINTING_INFO_PARCEL_AT_ARV_AGENCY',
    ];
  }

  getDeliveryInfoAssigned(): string[] {
    return ['DELIVERY_INFO_ASSIGNED'];
  }

  getPickedUp(): string[] {
    return ['PICKUP_INFO_PICKED_UP'];
  }

  getPickupIssue(): string[] {
    return [
      'PICKUP_ISSUE_PARCEL_UNAVAILABLE',
      'PICKUP_ISSUE_PARCEL_OUTSIZE',
      'PICKUP_ISSUE_SENDER_UNAVAILABLE',
      'PICKUP_ISSUE_SENDER_UNREACHABLE',
      'PICKUP_ISSUE_PARCEL_WITHOUT_LABEL',
      'PICKUP_ISSUE_CAR_ACCIDENT',
      'PICKUP_ISSUE_BROKEN_CAR',
      'PICKUP_ISSUE_WRONG_ADDRESS',
      'PICKUP_ISSUE_CANT_PARK',
      'PICKUP_ISSUE_CANT_ACCESS',
      'PICKUP_INFO_RESCHEDULED_BY_SENDER',
      'PICKUP_INFO_RESCHEDULED_BY_SERVICE',
      'PICKUP_INFO_RESCHEDULED_CONFIRMED',
      'PICKUP_ISSUE_LOCKER_DOWN',
      'PICKUP_ISSUE_LOCKER_NOT_FOUND',
      'PICKUP_ISSUE_LOCKER_WONT_OPEN',
      'PICKUP_ISSUE_LOCKER_ACCOUNT_ISSUE',
      'PICKUP_ISSUE_LOCKER_EMPTY',
      'POINTING_ISSUE_MISSING_AT_DEP_AGENCY',
      'POINTING_ISSUE_DAMAGED_AT_DEP_AGENCY',
      'POINTING_ISSUE_LOST_AT_DEP_AGENCY',
      'POINTING_ISSUE_REFUSED_BY_CARRIER',
    ];
  }

  getDeliveryIssue(): string[] {
    return [
      'POINTING_ISSUE_MISSING_AT_ARV_AGENCY',
      'POINTING_ISSUE_DAMAGED_AT_ARV_AGENCY',
      'POINTING_ISSUE_DAMAGED_IN_TRANSPORT',
      'POINTING_ISSUE_LOST_AT_ARV_AGENCY',
      'DELIVERY_ISSUE_RECIPIENT_UNAVAILABLE',
      'DELIVERY_ISSUE_RECIPIENT_UNREACHABLE',
      'DELIVERY_ISSUE_RECIPIENT_REFUSED',
      'DELIVERY_ISSUE_RECIPIENT_COD_REFUSED',
      'DELIVERY_ISSUE_CAR_ACCIDENT',
      'DELIVERY_ISSUE_BROKEN_CAR',
      'DELIVERY_ISSUE_WRONG_ADDRESS',
      'DELIVERY_ISSUE_CANT_PARK',
      'DELIVERY_ISSUE_CANT_ACCESS',
      'DELIVERY_ISSUE_LOCKER_DOWN',
      'DELIVERY_ISSUE_LOCKER_WONT_OPEN',
      'DELIVERY_ISSUE_LOCKER_ACCOUNT_ISSUE',
    ];
  }

  getPickupCanceled(): string[] {
    return [
      'PICKUP_INFO_CANCELED_BY_SENDER',
      'PICKUP_INFO_CANCELED_BY_SERVICE',
      'PICKUP_INFO_CANCELLATION_CONFIRMED',
    ];
  }

  getPickupRescheduled(): string[] {
    return [
      'PICKUP_INFO_RESCHEDULED_BY_SENDER',
      'PICKUP_INFO_RESCHEDULED_BY_SERVICE',
    ];
  }

  getPickupTerminated(): string[] {
    return ['POINTING_INFO_PARCEL_AT_DEP_AGENCY'];
  }

  getPointingToMMBag(): string[] {
    return ['POINTING_INFO_ASSIGNED_TO_MM_BAG'];
  }

  getPointingIssueAtDEP(): string[] {
    return [
      'POINTING_ISSUE_MISSING_AT_DEP_AGENCY',
      'POINTING_ISSUE_DAMAGED_AT_DEP_AGENCY',
      'POINTING_ISSUE_LOST_AT_DEP_AGENCY',
    ];
  }
  getPointingIssueAtARV(): string[] {
    return [
      'POINTING_ISSUE_DAMAGED_AT_ARV_AGENCY',
      'POINTING_ISSUE_DAMAGED_IN_TRANSPORT',
      'POINTING_ISSUE_LOST_AT_ARV_AGENCY',
      'POINTING_ISSUE_MISSING_AT_ARV_AGENCY',
    ];
  }

  getShipmentForecast(): string[] {
    return [
      'POINTING_INFO_PARCEL_AT_DEP_AGENCY',
      'POINTING_INFO_ASSIGNED_TO_MM_BAG',
    ];
  }
  getDelivered(): string[] {
    return ['DELIVERY_INFO_DELIVERED'];
  }

  getodeTrackingCrcInProgress(): string[] {
    return [
      'PICKUP_ISSUE_PARCEL_OUTSIZE',
      'PICKUP_ISSUE_SENDER_UNAVAILABLE',
      'PICKUP_ISSUE_SENDER_UNREACHABLE',
      'PICKUP_ISSUE_CAR_ACCIDENT',
      'PICKUP_ISSUE_BROKEN_CAR',
      'PICKUP_ISSUE_WRONG_ADDRESS',
      'PICKUP_ISSUE_CANT_PARK',
      'PICKUP_ISSUE_CANT_ACCESS',
      'PICKUP_INFO_RESCHEDULED_BY_SENDER',
      'PICKUP_ISSUE_LOCKER_DOWN',
      'PICKUP_ISSUE_LOCKER_WONT_OPEN',
      'PICKUP_ISSUE_LOCKER_ACCOUNT_ISSUE',
      'PICKUP_ISSUE_LOCKER_EMPTY',
      'POINTING_ISSUE_MISSING_AT_DEP_AGENCY',
      'POINTING_ISSUE_MISSING_AT_ARV_AGENCY',
      'DELIVERY_ISSUE_RECIPIENT_UNAVAILABLE',
      'DELIVERY_ISSUE_RECIPIENT_UNREACHABLE',
      'DELIVERY_ISSUE_RECIPIENT_REFUSED',
      'DELIVERY_ISSUE_RECIPIENT_COD_REFUSED',
      'DELIVERY_ISSUE_CAR_ACCIDENT',
      'DELIVERY_ISSUE_BROKEN_CAR',
      'DELIVERY_ISSUE_WRONG_ADDRESS',
      'DELIVERY_ISSUE_CANT_PARK',
      'DELIVERY_ISSUE_CANT_ACCESS',
      'DELIVERY_ISSUE_LOCKER_DOWN',
      'DELIVERY_ISSUE_LOCKER_WONT_OPEN',
      'DELIVERY_ISSUE_LOCKER_ACCOUNT_ISSUE',
      'DELIVER_ISSUE_MISSING_AT_ARV_AGENCY',
    ];
  }

  getTrackingPickupInProgress(): string[] {
    return [
      'INFO_NEW_ORDER',
      'PICKUP_INFO_UNASSIGNED',
    ];
  }

  getTrackingdeliveryInProgress(): string[] {
    return [
      'INFO_NEW_ORDER',
      'PICKUP_INFO_UNASSIGNED',
      'PICKUP_INFO_PICKED_UP',
      'PICKUP_INFO_RESCHEDULED_BY_SENDER',
      'PICKUP_INFO_RESCHEDULED_BY_SERVICE',
      'POINTING_INFO_PARCEL_AT_DEP_AGENCY',
      'POINTING_INFO_ASSIGNED_TO_MM_BAG',
      'POINTING_ISSUE_REFUSED_BY_CARRIER',
      'CRC_ASK_RETURN',
      'CLIENT_ASK_RETURN',
      'REQUEST_RETURN_CONFIRMED',
      'PARCEL_SHELVED',
      'POINTING_INFO_WAITING_TO_CHECK_AT_ARV_AGENCY',
      'POINTING_INFO_PARCEL_AT_ARV_AGENCY',
      'POINTING_INFO_ASSIGNED_TO_LM_BAG',
      'DELIVERY_INFO_ASSIGNED',
      'DELIVERY_INFO_UNASSIGNED',
      'DELIVERY_INFO_RESCHEDULE_CONFIRMED',
      'DELIVERY_INFO_REDELIVERY_CONFIRMED',
    ];
  }
}
