import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption, setRequestOption } from 'src/app/core/request/request-util';
import { Pagination } from 'src/app/core/request/request.model';
import { RequestSearch } from 'src/app/core/specification/requestSearch';
import { AppConstant } from 'src/app/shared/app-constant';
import { IOrderVM, OrderPagenationsVM } from '../models/order-vm.model';
import { ApplicationConfigService } from './../../../core/config/application-config.service';
import { IndicatorOrderVM } from '../../../shared/indicator-card/indicator-order-card.model';
import { ITrackingOrderVM } from '../models/tracking-order-vm.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private basic_url = "/orders";

  constructor(
    private http: HttpClient,
    private appConfig: ApplicationConfigService
  ) {}

  query( pagination: Pagination, idAgency: string, filters?: RequestSearch | null): Observable<OrderPagenationsVM> {
    let paramsQuery = createRequestOption(pagination);
    paramsQuery = setRequestOption(idAgency, "idAgency", paramsQuery);
    return this.http
      .post<OrderPagenationsVM>(
        this.appConfig.getEndpointFor(`${this.basic_url}/query`),
        filters,
        {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }


  assigning(idOrder: string, idUser: number): Observable<void> {
    return this.http
      .get<void>(
        this.appConfig.getEndpointFor(`/managers/orders/assign/${idOrder}/${idUser}`),
        AppConstant.httpOptions
      );
  }

  preAssign(idOrder: string, idUser: number): Observable<void> {
    return this.http
      .get<void>(
        this.appConfig.getEndpointFor(`/managers/orders/pre-assign/${idOrder}/${idUser}`),
        AppConstant.httpOptions
      );
  }



  getOrderByIdOrder(idOrder: string): Observable<IOrderVM> {
    return this.http
      .get<IOrderVM>(
        this.appConfig.getEndpointFor(`${this.basic_url}/by-id-order/${idOrder}`),
        AppConstant.httpOptions
      );
  }

  trackingOrdre(idOrder: number): Observable<ITrackingOrderVM[]> {
    return this.http
      .get<ITrackingOrderVM[]>(
        this.appConfig.getEndpointFor(`/trackingOrder/by-id-order/${idOrder}`),
        AppConstant.httpOptions
      );
  }

  updateAdressPickup(query: object): Observable<void> {
    const paramsQuery = createRequestOption(query);
    return this.http.put<void>(
      this.appConfig.getEndpointFor('/managers/orders/qualification-pickup-address'),
      null,
      {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }

  unassigned(idOrder: string): Observable<any> {
    return this.http
      .get(
        this.appConfig.getEndpointFor(`/managers/orders/unassigned/${idOrder}`),
        AppConstant.httpOptions
      );
  }

  cancelpreassigned(idOrder: string): Observable<any> {
    return this.http
      .get(
        this.appConfig.getEndpointFor(`/managers/orders/disable-pre-assigned/${idOrder}`),
        AppConstant.httpOptions
      );
  }

  qualifyDeliveryAddress(query: any): Observable<void> {
    const paramsQuery = createRequestOption(query);
    
    return this.http
      .put<void>(
        this.appConfig.getEndpointFor('/managers/orders/qualification-delivery-address'),
        null,
        {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }

  getIdicatorsPickup(idAgency: string): Observable<IndicatorOrderVM[]> {
    return this.http
      .get<IndicatorOrderVM[]>(
        this.appConfig.getEndpointFor(`/orders/indicators-pickup/${idAgency}`),
        {headers: AppConstant.httpOptions.headers}
      );
  }

  getIdicatorsDelivery(idAgency: string): Observable<IndicatorOrderVM[]> {
    return this.http
      .get<IndicatorOrderVM[]>(
        this.appConfig.getEndpointFor(`/orders/indicators-delivery/${idAgency}`),
        {headers: AppConstant.httpOptions.headers}
      );
  }
}
